/* eslint-disable */

import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { getImage } from "gatsby-plugin-image"

export default function Example({ images }) {
  return (
    <ul
      role="list"
      className="grid grid-cols-2 sm:grid-cols-3 gap-4 gap-y-8 sm:gap-6 lg:grid-cols-4 lg:gap-8"
    >
      {images.map(file => {
        const img = getImage(file.localImage)
        return (
          <li key={file.source} className="relative">
            <a
              href={file.permalink}
              target="__blank"
              rel="noreferrer"
              className="group block w-full aspect-w-10 aspect-h-7 rounded-lg bg-gray-100 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-offset-gray-100 focus-within:ring-indigo-500 overflow-hidden"
            >
              <div>
                {img && (
                  <GatsbyImage
                    className="object-cover pointer-events-none group-hover:opacity-75 absolute"
                    image={img}
                    alt={img?.alt ?? ""}
                  />
                )}
              </div>
              <button
                type="button"
                className="absolute inset-0 focus:outline-none"
              >
                <span className="sr-only">View details for {file.title}</span>
              </button>
            </a>
            <p className="mt-2 block text-sm font-medium text-gray-900 truncate pointer-events-none">
              {file.caption.split(".")[0]}
            </p>
          </li>
        )
      })}
    </ul>
  )
}
