import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"

import SingleQuote from "../components/singleQuote"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Instagram from "../components/instagram2"
import Divider from "../components/divider"
import Heading from "../components/heading"
import FourImageSection from "../components/fourImageSection"
import Hero from "../components/hero"
import CTAbooking from "../components/CTAbooking"

function IndexPage({ data }) {
  const image = getImage(data?.heroImg)
  const quoteImg = getImage(data?.quoteImg)
  const programCTA = getImage(data?.programCTA)

  return (
    <Layout>
      <SEO
        keywords={[`sincerely eden`, `hormone issues`, `female health couch`]}
        title="Sincerely Eden | Your hormone health go-to girl."
        description="I empower women to get to the SOURCE of their hormonal imbalances, create LASTING habits, and FLOURISH every single day of the month - just as nature intended."
      />
      <Hero
        cta={false}
        image={image}
        subtitle={
          <>
            <div className=" tracking-widest font-extralight">
              I empower women to get to the SOURCE of their hormonal imbalances,
              create LASTING habits, and FLOURISH every single day of the month
              - just as nature intended.
            </div>
          </>
        }
      />
      <Divider />
      <Heading
        title="my why"
        subtitle="I get you, because I have been you."
        description=""
      />
      <SingleQuote
        img={quoteImg}
        quote="After overcoming years of cystic acne, fatigue, anxiety, cravings, and a lack of period for most of my twenties, it’s now my passion to help you heal too - the natural way! I understand the feelings of defeat, the lack of confidence, the frustration with your treatment options, and the discomfort in your body, because I have been there. I have also seen many women turn to prescriptions, surgeries, and restrictive modalities without any awareness of the less invasive options. I want to pull you out of your state of hopelessness, and provide you with the tools you need to transform into the healthiest and happiest version of you. Because you so deserve it, Darling."
      />
      <Divider />
      <FourImageSection
        title="My difference"
        subtitle="I’m with you, every step of the way"
        description=" Success isn’t about the amount of knowledge we have, or most of us wouldn’t be practicing things we know aren’t serving us. "
        features={[
          {
            name: "Take Action",
            description: "I bridge the gap between knowing & doing",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "Holistic Approach",
            description:
              "My recommendations focus on decreasing stress in, not just your dietary habits, but lifestyle overall",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "Constant Support",
            description:
              "I am your ongoing cheerleader - especially on the days things get tough",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          },
          {
            name: "Accountability Partner",
            description:
              "I am your accountability partner to ensure you’re continually transforming & meeting your goals",
            imageAlt:
              "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
          }
        ]}
      />
      <Divider />
      <CTAbooking programCTA={programCTA} />
      {/* <Newsletter /> */}
      <Divider />
      {/* <Features /> */}
      {/* <Divider /> */}
      <Heading
        title=""
        subtitle={
          <>
            Follow along on instagram |{" "}
            <a
              className="text-sea-pink-700"
              href="https://www.instagram.com/sincerelyeden"
              target="__blank"
              rel="noreferrer"
            >
              @sincerelyeden
            </a>
          </>
        }
        description=""
      />
      <Instagram images={data.instagram.nodes} />
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query HeroImageQuery {
    instagram: allInstagramContent(limit: 8) {
      nodes {
        caption
        permalink
        localImage {
          childImageSharp {
            gatsbyImageData(
              quality: 80
              layout: CONSTRAINED
              placeholder: BLURRED
            )
          }
        }
      }
    }
    programCTA: file(relativePath: { eq: "sitting-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 300
          height: 300
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    heroImg: file(relativePath: { eq: "beach-fruit.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 650
          quality: 90
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    quoteImg: file(relativePath: { eq: "zoomed-about-me.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 600
          height: 650
          quality: 100
          transformOptions: { cropFocus: NORTHWEST }
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`
