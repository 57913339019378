import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Heading from "./heading"

export const pageQuery = graphql`
  query FourImageSection {
    image1: file(relativePath: { eq: "eden-friend.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 490
          height: 490
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    image2: file(relativePath: { eq: "laying-back-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 490
          height: 490
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    image3: file(relativePath: { eq: "laying-stomach-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 490
          height: 490
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    image4: file(relativePath: { eq: "couch-white.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          width: 490
          height: 490
          quality: 100
          placeholder: BLURRED
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
  }
`

const defaultFeatures = [
  {
    name: "Three card types",
    description:
      "Today, Next, and Someday cards allow you to defer your dreams into the future.",
    imageAlt: "Green cardstock box containing white, beige, and brown cards."
  },
  {
    name: "The perfect mix",
    description:
      "Each refill pack contains plenty of cards to last you a month of procrastination.",
    imageAlt: "Green cardstock box open with 50 cards inside."
  },
  {
    name: "Dot grid backs",
    description:
      "Flip a card over to doodle during meetings when you should be listening.",
    imageAlt:
      "Detail of white today card, beige next card, and brown someday card with dot grid."
  },
  {
    name: "Refill packs",
    description:
      "Subscribe and save on routine refill packs to keep you productive all year long.",
    imageAlt:
      "Stack of three green cardstock boxes with 3 hole cutouts showing cards inside."
  }
]

export default function Example({
  features = defaultFeatures,
  title = "Benefits",
  subtitle = "Lorem ipsum dolor sit amet",
  description = "Lorem ipsum dolor sit amet, consectetur adipiscing elit"
}) {
  const data = useStaticQuery(pageQuery)
  const withFriend = getImage(data?.image1)
  const laydown = getImage(data?.image2)
  const shadowFriend = getImage(data?.image3)
  const sitdown = getImage(data?.image4)

  const images = [laydown, sitdown, withFriend, shadowFriend]
  return (
    <div className="bg-white py-12">
      <Heading
        title={title}
        subtitle={subtitle}
        description={description}
        layout="left"
      />
      <div className="max-w-2xl mx-auto  px-4 sm:px-6 lg:max-w-7xl lg:px-8">
        <div className="mt-11 grid items-start grid-cols-1 gap-y-16 gap-x-6 sm:mt-16 sm:grid-cols-2 lg:grid-cols-4 lg:gap-x-8">
          {features.map((feature, i) => (
            <div key={feature.name} className="flex flex-col-reverse">
              <div className="mt-6">
                <p className="text-lg leading-6 font-medium text-gray-900">
                  {feature.name}
                </p>
                <p className="mt-2 text-base text-jet">{feature.description}</p>
              </div>
              <div className="aspect-w-1 aspect-h-1 rounded-lg bg-gray-100 overflow-hidden">
                <div>
                  <GatsbyImage
                    className="object-center object-cover absolute"
                    image={images[i]}
                    alt={feature?.imageAlt ?? ""}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}
