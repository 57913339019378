/* eslint-disable */

import React from "react"
import Gallery from "./gallery"

function Instagram({ images }) {
  return (
    <div className="">
      <div className="mt-16  mx-auto px-4 sm:px-6 lg:max-w-7xl lg:px-8 mb-16">
        <Gallery images={images} />
      </div>
    </div>
  )
}

export default Instagram
